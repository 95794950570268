export default {
  props: {
    options: Object,
    items: Array,
    itemsLength: Number,
    loading: Boolean,
    opened: Array,
    selectedState: Object
  },
  methods: {
    detail (id, event = null) {
      if (this.opened) {
        this.addOpenedItem(id)
      }
      if (!event || event.target.className.indexOf('v-input--selection-controls') === -1) {
        this.$emit('detail', { id })
      }
    },
    addOpenedItem (itemId) {
      if (!this.opened.find(val => val === itemId)) {
        let opened = this.opened
        opened.push(itemId)
        this.$emit('update:opened', opened)
      }
    },
    //todo: Два метода ниже одинаковые. Чтобы было по уму надо в родители провести
    // преобразование для каждого итема массива и передавать уже значение сюда.
    valueForColumn (item, attribute) {
      let result = 'Нет'
      if (this.client_id && item.userAdverts?.length) {
        let connection = item.userAdverts.find(connection => connection.client_id === this.client_id)
        if (connection && connection[attribute] === 1) {
          result = 'Да'
        }
      }
      return result
    },
    classForColumn (item, attribute) {
      let result = 'statusRed'
      if (this.client_id && item.userAdverts?.length) {
        let connection = item.userAdverts.find(connection => connection.client_id === this.client_id)
        if (connection && connection[attribute] === 1) {
          result = 'statusGreen'
        }
      }
      return result
    }
  }
}
